<template>
  <b-card>
    <b-overlay
      variant="transparent"
      :show="isProcessing"
    >
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              :label="t('settings.changePassword.oldPassword')"
              label-for="account-old-password"
            >
              <input-password
                id="account-old-password"
                v-model="model.oldPassword"
                name="old-password"
                :error="errors.oldPassword"
              />
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>

        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="new-password"
              :label="t('settings.changePassword.newPassword')"
            >
              <input-password
                v-model="model.newPassword"
                :error="errors.newPassword"
              />
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- passwordConfirm -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="t('settings.changePassword.passwordConfirm')"
            >
              <input-password
                id="account-retype-new-password"
                v-model="model.passwordConfirm"
                name="retype-password"
                :error="errors.passwordConfirm"
              />
            </b-form-group>
          </b-col>
          <!--/ passwordConfirm -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="onSubmit"
            >
              {{ t('settings.save') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BOverlay,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { required, email, min } from '@validations';
import { ref, computed } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import InputPassword from '@/components/inputPassword/InputPassword.vue';
import { useValidation } from '@/hooks/useValidation';
import {
  fieldRequiredValidator,
  fieldsMatchValidator,
  maxLengthValidator,
  minLengthValidator,
} from '@/utils/validation';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BOverlay,
    InputPassword,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { welcomeToast, dangerToast } = useToast();

    const passwordForm = ref(null);
    const model = ref({
      oldPassword: '',
      newPassword: '',
      passwordConfirm: '',
    });

    const passwordMinLength = computed(() => store.getters['user/passwordMinLength']);
    const passwordMaxLength = computed(() => store.getters['user/passwordMaxLength']);

    const [validate, errors] = useValidation([
      fieldRequiredValidator(model.value, 'oldPassword', t('errors.message.oldPasswordRequired')),
      fieldRequiredValidator(model.value, 'newPassword', t('errors.message.newPasswordRequired')),
      minLengthValidator(
        model.value,
        'newPassword',
        passwordMinLength,
        t('errors.message.passwordMinLength', { length: passwordMinLength }),
      ),
      maxLengthValidator(
        model.value,
        'newPassword',
        passwordMaxLength,
        t('errors.message.passwordMaxLength', { length: passwordMaxLength }),
      ),
      fieldRequiredValidator(
        model.value,
        'passwordConfirm',
        t('errors.message.passwordConfirmRequired'),
      ),
      fieldsMatchValidator(
        model.value,
        'newPassword',
        'passwordConfirm',
        t('errors.message.passwordsNotMatch'),
      ),
    ]);

    const isProcessing = ref(false);

    const onSubmit = async () => {
      if (isProcessing.value) return;

      const isFormValid = validate();
      if (!isFormValid) return;

      isProcessing.value = true;
      const { result, status } = await store.dispatch('user/changePassword', {
        oldPassword: model.value.oldPassword,
        newPassword: model.value.newPassword,
      });
      isProcessing.value = false;

      if (result) {
        welcomeToast(
          t('settings.changePassword.done.title'),
          t('settings.changePassword.done.text'),
        );
        return;
      }

      if (status === 400) {
        dangerToast(
          t('settings.changePassword.wrongPasswordError.title'),
          t('settings.changePassword.wrongPasswordError.text'),
        );
      }
    };

    return {
      t,

      passwordForm,
      model,
      errors,
      isProcessing,
      onSubmit,

      required,
      email,
      min,
    };
  },
};
</script>
<style lang="css">
.password {
  font: small-caption !important;
  font-size: 20px !important;
}
</style>
